<template>
    <v-card>
        <v-card v-if="employerData">
            <v-card-text>
                <psi-form-section
                    two-cols
                    :value="employerData"
                    name="profile-address-data"
                    :label="['Profile', 'Address']"
                    :fields="mergedFields(profileFields, addressFields)"
                    :color="color"
                    @input="update"
                ></psi-form-section>
                <psi-form-section
                    two-cols
                    :value="employerData"
                    name="additional-data"
                    label="Additional Information"
                    :fields="groupedFields(metaFields)"
                    :color="color"
                    @input="update"
                ></psi-form-section>
            </v-card-text>
            <v-card-actions class="mr-2">
                <v-spacer></v-spacer>
                <v-btn
                    color="grey lighten-2"
                    class="mr-2"
                    @click.stop="$emit('close')"
                >
                    <v-icon class="mr-1">mdi-close</v-icon> Cancel
                </v-btn>
                <v-btn color="primary darken-1" @click.stop="save">
                    <v-icon class="mr-1">mdi-account</v-icon> Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-card>
</template>
<script>
import { mapActions } from "vuex";

export default {
    name: "employment-employer-edit",
    components: {},
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            n: 0,
            employerData: this.data,
            color: "accent darken-1", // primary color for this control
            profileFields: [
                {
                    type: "text",
                    name: "name",
                    key: "primary_contact.name",
                    label: "Name",
                    icon: this.$config("icons.name"),
                    required: true,
                },
                {
                    type: "text",
                    format: "email",
                    name: "email",
                    key: "primary_contact.email",
                    label: "Email",
                    icon: this.$config("icons.email"),
                    required: true,
                },
                {
                    type: "text",
                    name: "phone",
                    key: "primary_contact.phone",
                    label: "Phone",
                    icon: this.$config("icons.phone"),
                    format: "tel",
                    required: true,
                },
                {
                    type: "text",
                    name: "fax",
                    key: "primary_contact.fax",
                    label: "Fax",
                    icon: this.$config("icons.fax"),
                    format: "tel",
                    required: false,
                },
            ],
            addressFields: [
                {
                    type: "text",
                    name: "address",
                    key: "primary_contact.address",
                    label: "Address",
                    icon: this.$config("icons.address"),
                    required: true,
                },

                {
                    type: "text",
                    name: "city",
                    key: "primary_contact.city",
                    label: "City",
                    icon: this.$config("icons.city"),
                    required: true,
                },
                {
                    type: "text",
                    id: this.$uuid.v4(),
                    name: "state",
                    key: "primary_contact.state",
                    label: "State",
                    icon: this.$config("icons.state"),
                    required: true,
                    placeholder: "Enter state",
                    outerIcon: "",
                },
                {
                    type: "text",
                    name: "zip",
                    key: "primary_contact.zip",
                    label: "Zip Code",
                    icon: this.$config("icons.zip"),
                    required: true,
                },
            ],
            metaFields: [
                {
                    type: "select",
                    name: "type",
                    key: "type",
                    label: "Type",
                    icon: this.$config("icons.employer_type"),
                    required: true,
                    items: [
                        {
                            text: "Current",
                            value: "Current",
                        },
                        {
                            text: "Previous",
                            value: "Previous",
                        },
                    ],
                },
                {
                    type: "blank",
                    name: "blank",
                    key: "blank",
                    label: "",
                    icon: "",
                },
                {
                    type: "text",
                    name: "company",
                    key: "company",
                    label: "Company",
                    icon: this.$config("icons.company"),
                    required: true,
                },
                {
                    type: "select",
                    name: "type",
                    key: "fullpart",
                    label: "Type",
                    icon: this.$config("icons.employment_type"),
                    required: true,
                    // TODO: get this list from the backend model
                    items: [
                        {
                            text: "Full-time Employment",
                            value: "full",
                        },
                        {
                            text: "Part-time Employment",
                            value: "part",
                        },
                    ],
                },

                {
                    type: "date",
                    name: "start_date",
                    key: "start_date",
                    label: "Start Date",
                    required: true,
                    icon: this.$config("icons.start_date"),
                },
                {
                    type: "date",
                    name: "end_date",
                    key: "end_date",
                    label: "End Date",
                    icon: this.$config("icons.end_date"),
                },

                {
                    type: "text",
                    name: "position",
                    key: "position",
                    label: "Position",
                    icon: this.$config("icons.position"),
                    required: true,
                },
                {
                    id: this.$uuid.v4(),
                    type: "currency",
                    key: "monthly_income",
                    name: "monthly_income",
                    label: "Monthly Income",
                    icon: this.$config("icons.monthly_income"),
                    required: true,
                },
            ],
        };
    },
    computed: {},
    methods: {
        ...mapActions("Screens", ["saveCurrentScreenEmployer"]),

        save() {
            // save the address to the store
            this.saveCurrentScreenEmployer(this.employerData);
            // send a snackbar message
            this.$emit("close");
        },
        update(employerData) {
            this.employerData = employerData;
            if (employerData.type === "Current") {
                this.metaFields = this.metaFields.map((item) => {
                    if (item.name === "end_date") {
                        item.disabled = true;
                        item.required = false;
                    }
                    return item;
                });
            }
            if (employerData.type === "Previous") {
                this.metaFields = this.metaFields.map((item) => {
                    if (item.name === "end_date") {
                        item.disabled = false;
                        item.required = true;
                    }
                    return item;
                });
            }
        },
        mergedFields(fields1, fields2) {
            return fields1.map((field, index) => {
                return index < fields2.length ? [field, fields2[index]] : field;
            });
        },
        groupedFields(fields) {
            // create a group of 2 column meta fields
            let fieldGroup = [];
            for (let i = 0; i < fields.length; i += 2) {
                let items = [];
                items.push(fields[i]);
                if (i + 1 < fields.length) {
                    items.push(fields[i + 1]);
                }
                fieldGroup.push(items);
            }
            return fieldGroup;
        },
    },
    watch: {},
};
</script>

<style scoped>
</style>